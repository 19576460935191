@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Element
// ==================================================
html {
  font-size: 62.5%;
}
body {
  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
    overflow-wrap: break-word;
  }

  form * {
    -webkit-appearance: auto;
  }
}
a {
  color: color.$site-link;

  &:hover {
    text-decoration: none;
  }

  &[href^="tel:"] {
    @include mixin.pc {
      pointer-events: none;
      cursor: default;
      text-decoration: none;
    }
  }
}
img {
  max-width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
}
b,
strong {
  font-weight: 600;
}
table {
  border-collapse: collapse;
  border: none;
  border-spacing: 0;
}
button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}
