@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "index";
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Hide
// ==================================================
.hide {
  &-pc {
    @include mixin.pc {
      display: none !important;
    }
  }
  &-sp {
    @include mixin.sp {
      display: none !important;
    }
  }
}

// ==================================================
//  Body
// ==================================================
body {
  // Noto Sans JP
  font-family: "Inter", "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", YuGothic, "Yu Gothic", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  line-height: calc(37 / 16);
  min-width: 1080px;
  touch-action: manipulation;

  @include mixin.sp {
    font-size: 1.4rem;
    line-height: calc(30 / 14);
    min-width: 375px;
  }
}

// ==================================================
//  Header
// ==================================================
.header {
  display: block;
  width: 100%;
  height: 92px;
  margin-bottom: -92px;

  @include mixin.sp {
    height: 90px;
    margin-bottom: -90px;
  }

  // Inner
  &_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 80px;
    width: 100%;
    padding: 53px 60px 0 35px;

    @include mixin.sp {
      align-items: flex-start;
      column-gap: 20px;
      padding: 19px 24px 0 15px;
    }

    // Modifier
    &-top {
      @include mixin.pc {
        padding: 33px 60px 0 36px;
      }

      .header_logo {
        @include mixin.pc {
          max-width: 122px;
        }

        &_a {
          @include mixin.pc {
            display: block !important;
          }
        }

        &_b {
          @include mixin.pc {
            display: none !important;
          }
        }
      }
    }

    // Javascript
    &.js-header-fixed {
      min-width: 1200px;
      position: fixed;
      z-index: 80;
      top: 0;
      left: 0;

      @include mixin.sp {
        min-width: 375px;
      }
    }

    &.js-header-minimal {
      transition: padding 0.3s ease, background-color 0.3s ease;

      // Modifier
      &-active {
        padding-top: 22px;

        @include mixin.pc {
          padding-bottom: 22px;
          background-color: rgba(255, 255, 255, 0.9);
        }

        @include mixin.sp {
          padding-top: 29px;
        }

        .header_logo {
          max-width: 248px;

          @include mixin.sp {
            max-width: 167px;
          }

          &_a {
            display: none !important;
          }

          &_b {
            display: block !important;
          }
        }
      }
    }

    &_left {
    }

    &_right {
    }
  }

  // Logo
  &_logo {
    display: block;
    width: 100%;
    max-width: 248px;
    margin: 0;

    @include mixin.sp {
      max-width: 88px;
    }

    // Javascript
    &.js-sp-headerLogo {
      // Modifier
      &-active {
        max-width: 167px;
        top: 10px;
      }
    }

    a,
    img {
      display: block;
      width: 100%;
    }

    a {
      @include mixin.hoverOpacity60;
    }

    &_a {
      display: none !important;

      @include mixin.sp {
        display: block !important;
      }
    }

    &_b {
      display: block !important;

      @include mixin.sp {
        display: none !important;
      }
    }
  }

  // Menu Logo
  &_menuLogo {
    display: none;

    @include mixin.sp {
      display: block;
      width: 100%;
      max-width: 167px;
      position: absolute;
      z-index: 85;
      top: 29px;
      left: 15px;
    }

    // Javascript
    &.js-sp-headerMenuLogo {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease;

      // Modifier
      &-active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  // Menu Button
  &_menuButton {
    display: none;

    @include mixin.sp {
      all: unset;
      display: block;
      width: 36px;
      height: auto;
      aspect-ratio: 1/1.25;
      position: absolute;
      top: 31px;
      right: 25px;
      z-index: 85;
    }

    // Javascript
    &.js-sp-headerMenuButton {
      @include mixin.sp {
        background-image: url(../img/common/ico_menu.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
      }

      // Modifier
      &-active {
        @include mixin.sp {
          background-image: url(../img/common/ico_menu_close.svg);
        }

        .header_menuButton_text {
          //Modifier
          &-menu {
            @include mixin.sp {
              display: none !important;
            }
          }
          &-close {
            @include mixin.sp {
              display: block !important;
            }
          }
        }
      }

      .header_menuButton_text {
        //Modifier
        &-menu {
          @include mixin.sp {
            display: block;
          }
        }
        &-close {
          @include mixin.sp {
            display: none;
          }
        }
      }
    }

    &_text {
      @include mixin.sp {
        display: block;
        max-width: none;
        @include mixin.font-montserrat;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: calc(16 / 12);
        text-align: center;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }

      // Modifier
      &-menu {
        @include mixin.sp {
          color: inherit;
        }
      }
      &-close {
        @include mixin.sp {
          color: #fff;
          display: none;
        }
      }
    }
  }

  // Navigation
  &_navigation {
    @include mixin.sp {
      display: block;
      width: 100%;
      height: 100%;
      padding: 102px 40px 51px;
      overflow: auto;
      color: #fff;
      background-color: #000;
      position: fixed;
      top: 0;
      left: 0;
    }

    // Javascript
    &.js-sp-headerMenu {
      @include mixin.sp {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }

      // Modifier
      &-show {
        @include mixin.sp {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &_list {
      display: flex;
      column-gap: 40px;
      align-items: center;
      list-style: none;
      padding: 0;
      margin: 0;

      @include mixin.sp {
        flex-direction: column;
        align-items: flex-start;
        column-gap: 0;
        row-gap: 23px;
      }

      &_item {
        font-size: 1.5rem;
        line-height: calc(22 / 15);
        font-weight: 500;
        margin: 0;

        @include mixin.sp {
          font-size: 1.6rem;
          line-height: calc(23 / 16);
        }

        &_inner {
          color: inherit;
          text-decoration: none;
          position: relative;
          // @include mixin.hoverOpacity60;

          @include mixin.hover {
            &::after {
              transform: scale(1, 1);
              transform-origin: top left;
            }
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: color.$site-write;
            transform: scale(0, 1);
            transition: transform 0.3s ease;
            transform-origin: top right;
          }
        }
      }
    }

    &_spLink {
      display: none;

      @include mixin.sp {
        display: flex;
        flex-direction: column;
        row-gap: 19px;
        width: 100%;
        margin: 40px 0 0;
        color: #fff;
      }

      &_item {
        @include mixin.sp {
          display: block;
          font-size: 1.3rem;
          line-height: calc(19 / 13);
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}

// ==================================================
//  Main
// ==================================================
.main {
  display: block;
  width: 100%;
  padding: 133px 0 0 0;

  @include mixin.sp {
    padding-top: 119px;
  }

  // Breadcrumb（WordPress YoastSEO Plugins）
  &_breadcrumb {
    display: block;
    width: 100%;
    overflow: auto;

    &_inner {
      display: block;
      justify-content: flex;
      width: 100%;
      max-width: 1120px;
      margin: 0 auto;
      font-size: 1.2rem;
      line-height: calc(17 / 12);
      text-align: right;

      @include mixin.sp {
        width: calc(100% - 60px);
        font-size: 1.1rem;
        line-height: calc(16 / 11);
        text-align: left;
      }

      a {
        text-decoration: none;
        color: #b1b1b1;

        &::after {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 12px;
          height: 1px;
          margin: 0 12px;
          transform: translateY(-1px);
          background-color: #d2d2d2;

          @include mixin.sp {
            width: 8px;
            margin: 0 8px;
          }
        }
      }
    }
  }

  // Page Head
  &_pageHead {
    display: block;
    width: 100%;
    max-width: 1120px;
    margin: 18px auto 100px;
    padding: 0;

    @include mixin.sp {
      width: calc(100% - 60px);
      margin: 48px auto;
    }

    // Modifier
    &-columnPost {
      @include mixin.pc {
        max-width: 960px;
        margin-bottom: 34px;
      }
    }
    &-worksPost {
      @include mixin.pc {
        max-width: 960px;
      }
    }
    &-caseStudyPost {
      @include mixin.pc {
        max-width: 960px;
        margin-bottom: 27px;
      }

      @include mixin.sp {
        margin-bottom: 27px;
      }
    }

    &_category {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin: 49px 0 20px;

      @include mixin.sp {
        gap: 4px;
        margin: 48px 0 10px;
      }

      &_item {
        display: block;
        font-size: 1.4rem;
        line-height: calc(20 / 14);
        background-color: #e4e4e4;
        border-radius: 50px;
        padding: 2px 10px;

        @include mixin.sp {
          font-size: 1.2rem;
          line-height: calc(20 / 12);
          padding: 2px 10px 1px;
        }
      }
    }

    &_title {
      &En {
        @include mixin.font-montserrat;
        display: block;
        font-size: 1.5rem;
        line-height: calc(18 / 15);
        font-weight: 500;
        margin: 0 0 10px;

        @include mixin.sp {
          font-size: 1.1rem;
          line-height: calc(13 / 11);
          margin-bottom: 6px;
        }
      }

      &Ja {
        font-size: 3.4rem;
        line-height: calc(49 / 34);
        font-weight: 600;
        margin: 0 0 48px;

        @include mixin.sp {
          font-size: 2.2rem;
          line-height: calc(32 / 22);
          margin-bottom: 24px;
        }
      }
    }

    &_paragraph {
      font-size: 1.6rem;
      line-height: calc(37 / 16);
      font-weight: 500;
      margin: 16px 0 0;

      @include mixin.sp {
        font-size: 1.4rem;
        line-height: calc(26 / 14);
        margin-top: 14px;
      }
    }
  }

  // Serivce
  &_service {
    display: block;
    width: 100%;
    padding: 100px 120px 147px 70px;
    margin: 0 0 0 auto;
    position: relative;
    z-index: 1;

    @include mixin.sp {
      width: 100%;
      padding: 60px 0 84px;
      margin: 0 auto;
    }

    &::before {
      content: "";
      display: block;
      width: calc(100% - 50px);
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      background-color: #fafafa;

      @include mixin.sp {
        width: 100%;
      }
    }

    &_inner {
      display: block;
      width: 100%;
      max-width: 1120px;
      margin: 0 auto;
      position: relative;
      z-index: 3;

      @include mixin.sp {
        width: calc(100% - 60px);
      }
    }

    &_head {
      display: flex;
      align-items: flex-end;
      column-gap: 70px;
      width: 100%;

      @include mixin.sp {
        flex-direction: column;
        align-items: flex-start;
        column-gap: 0;
        row-gap: 20px;
      }

      &_title {
        display: block;
        white-space: nowrap;

        &_en {
          display: block;
          font-size: 1.5rem;
          line-height: calc(18 / 15);
          font-weight: 500;
          margin-bottom: 9px;

          @include mixin.sp {
            font-size: 1.1rem;
            line-height: calc(13 / 11);
            margin-bottom: 6px;
          }
        }

        &_ja {
          font-size: 3.4rem;
          line-height: calc(49 / 34);
          font-weight: 700;
          margin: 0;

          @include mixin.sp {
            font-size: 2.2rem;
            line-height: calc(32 / 22);
            letter-spacing: 1.1px;
          }
        }
      }

      &_description {
        font-size: 1.6rem;
        line-height: calc(23 / 16);
        font-weight: 500;
        margin: 0 0 13px;

        @include mixin.sp {
          font-size: 1.4rem;
          line-height: calc(26 / 14);
          margin: 0;
        }
      }
    }

    &_content {
      display: flex;
      flex-wrap: wrap;
      column-gap: 67px;
      row-gap: 67px;
      width: 100%;
      margin: 109px 0 0;

      @include mixin.sp {
        column-gap: 16px;
        row-gap: 35px;
        margin-top: 47px;
      }

      &_item {
        display: block;
        position: relative;

        &::before {
          height: auto;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% auto;
          position: absolute;
        }

        // Modifier
        &-web {
          padding-left: 105px;

          @include mixin.sp {
            padding-left: 59px;
          }

          &::before {
            content: "";
            width: 50px;
            aspect-ratio: 1/1.24;
            background-image: url(../img/common/tsumiki_a.svg);
            top: -15px;
            left: 40px;

            @include mixin.sp {
              width: 38px;
              top: -7px;
              left: 0;
            }
          }
        }
        &-design {
          padding-left: 107px;

          @include mixin.sp {
            padding-left: 59px;
          }

          &::before {
            content: "";
            width: 59px;
            aspect-ratio: 1/1.169;
            background-image: url(../img/common/tsumiki_b.svg);
            top: -16px;
            left: 33px;

            @include mixin.sp {
              width: 46px;
              top: -13px;
              left: 0;
            }
          }
        }
        &-support {
          padding-left: 77px;

          @include mixin.sp {
            padding-left: 59px;
          }

          &::before {
            content: "";
            width: 62px;
            aspect-ratio: 1/1.113;
            background-image: url(../img/common/tsumiki_c.svg);
            top: -16px;
            left: 0;

            @include mixin.sp {
              width: 45px;
              top: -10px;
              left: 0;
            }
          }
        }

        &_title {
          font-size: 2.2rem;
          line-height: calc(32 / 22);
          font-weight: 700;
          margin: 0;

          @include mixin.sp {
            font-size: 1.7rem;
            line-height: calc(25 / 17);
          }
        }

        &_list {
          display: block;
          width: 100%;
          list-style: none;
          margin: 25px 0 0;
          padding: 0;

          @include mixin.sp {
            margin-top: 13px;
          }

          &_item {
            font-size: 1.6rem;
            line-height: calc(26 / 16);
            margin: 2px 0 0;
            padding: 0 0 0 22px;
            position: relative;

            @include mixin.sp {
              font-size: 1.4rem;
              line-height: calc(26 / 14);
              padding-left: 17px;
            }

            &::before {
              content: "";
              display: block;
              width: 8px;
              height: auto;
              aspect-ratio: 1/1;
              background-color: #949494;
              border-radius: 100%;
              position: absolute;
              top: 10px;
              left: 0;

              @include mixin.sp {
                top: 9px;
              }
            }
          }
        }
      }
    }
  }

  // Case Study
  &_caseStudy {
    display: block;
    width: 100%;
    max-width: 1120px;
    padding: 100px 0 77px;
    margin: 0 auto;

    @include mixin.sp {
      width: calc(100% - 60px);
      padding: 60px 0 52px;
    }

    &_head {
      display: flex;
      align-items: flex-end;
      column-gap: 70px;
      width: 100%;

      @include mixin.sp {
        flex-direction: column;
        align-items: flex-start;
        column-gap: 0;
        row-gap: 20px;
      }

      &_title {
        display: block;
        white-space: nowrap;

        &_en {
          display: block;
          font-size: 1.5rem;
          line-height: calc(18 / 15);
          font-weight: 500;
          margin-bottom: 9px;

          @include mixin.sp {
            font-size: 1.1rem;
            line-height: calc(13 / 11);
            margin-bottom: 6px;
          }
        }

        &_ja {
          font-size: 3.4rem;
          line-height: calc(49 / 34);
          font-weight: 700;
          margin: 0;

          @include mixin.sp {
            font-size: 2.2rem;
            line-height: calc(32 / 22);
            letter-spacing: 1.1px;
          }
        }
      }

      &_description {
        font-size: 1.6rem;
        line-height: calc(23 / 16);
        font-weight: 500;
        margin: 0 0 13px;

        @include mixin.sp {
          font-size: 1.4rem;
          line-height: calc(26 / 14);
          margin: 0;
        }
      }
    }

    &_list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 40px;
      row-gap: 40px;
      width: 100%;
      margin: 50px 0 0;
      padding: 0;

      @include mixin.sp {
        column-gap: 0;
        margin-top: 40px;
      }

      &_item {
        display: block;
        width: calc((100% / 2) - (40px / 2));

        @include mixin.sp {
          width: 100%;
        }

        &_inner {
          text-decoration: none;
          color: inherit;

          @include mixin.hover {
            .main_caseStudy_list_item_image {
              &_inner {
                transform: scale(1.1);
              }
            }
          }
        }

        &_image {
          display: block;
          width: 100%;
          height: auto;
          aspect-ratio: 1/0.5556;
          overflow: hidden;

          &_inner {
            display: block;
            width: 100%;
            height: 100%;
            transform: scale(1);
            transform-origin: center;
            transition: transform 0.5s ease;

            & > img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        &_title {
          font-size: 2.2rem;
          line-height: calc(36 / 22);
          font-weight: 500;
          margin: 24px 0 0;

          @include mixin.sp {
            font-size: 1.5rem;
            line-height: calc(26 / 15);
            margin-top: 12px;
          }
        }

        &_information {
          display: block;
          width: 100%;
          margin: 19px 0 0;
          padding: 0;

          @include mixin.sp {
            margin-top: 6px;
          }

          &_item {
            display: flex;
            align-items: flex-start;
            font-size: 1.6rem;
            line-height: calc(23 / 16);
            margin-top: 6px;

            @include mixin.sp {
              font-size: 1.3rem;
              line-height: calc(19 / 13);
              margin-top: 3px;
            }

            &_title {
              width: 80px;
              padding-right: 10px;

              @include mixin.sp {
                width: 68px;
              }
            }

            &_content {
              width: calc(100% - 80px);
              margin: 0;

              @include mixin.sp {
                width: calc(100% - 68px);
              }
            }
          }
        }
      }
    }

    &_link {
      display: block;
      width: 100%;
      max-width: 293px;
      font-size: 1.8rem;
      line-height: calc(26 / 18);
      font-weight: 500;
      position: relative;
      padding-right: 44px;
      margin: 73px auto 0;
      color: inherit;
      text-decoration: none;

      @include mixin.sp {
        max-width: 254px;
        font-size: 1.5rem;
        line-height: calc(22 / 15);
        padding-right: 43px;
        margin-top: 51px;
      }

      &_inner {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: color.$site-write;
          transform: scale(0, 1);
          transition: transform 0.3s ease;
          transform-origin: top right;
        }
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 33px;
        height: auto;
        aspect-ratio: 1/1;
        background-color: color.$site-write;
        border-radius: 2px;
        position: absolute;
        top: calc(50% + 1px);
        right: 1px;
        transform: translateY(-50%);
        transition: right 0.3s ease;

        @include mixin.sp {
          width: 27px;
        }
      }

      &::after {
        background-color: transparent;
        background-image: url(../img/common/ico_arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px auto;

        @include mixin.sp {
          background-size: 12px auto;
        }
      }

      @include mixin.hover {
        .main_caseStudy_link_inner {
          &::after {
            transform: scale(1, 1);
            transform-origin: top left;
          }
        }

        &::after {
          // right: -3px;
          animation-name: animation_arrow_toright;
          animation-duration: 0.5s;
          animation-timing-function: ease;
        }
      }

      // Modifier
      &-back {
        padding-left: 44px;
        padding-right: 0;

        @include mixin.sp {
          padding-left: 43px;
          padding-right: 0;
        }

        .main_works_link_inner {
          &::after {
            transform-origin: top left;
          }
        }

        &::before,
        &::after {
          top: 50%;
          right: auto;
          left: 1px;
          transform: translateY(-50%) rotate(180deg);
        }

        @include mixin.hover {
          .main_works_link_inner {
            &::after {
              transform: scale(1, 1);
              transform-origin: top right;
            }
          }

          &::after {
            // right: -3px;
            animation-name: animation_linkback_arrow_toleft;
            animation-duration: 0.5s;
            animation-timing-function: ease;
          }
        }
      }
    }
  }

  // Works
  &_works {
    display: block;
    width: 100%;
    max-width: 1120px;
    padding: 77px 0;
    margin: 0 auto;

    @include mixin.sp {
      width: calc(100% - 60px);
      padding: 52px 0;
    }

    &_head {
      display: flex;
      align-items: flex-end;
      column-gap: 70px;
      width: 100%;

      @include mixin.sp {
        flex-direction: column;
        align-items: flex-start;
        column-gap: 0;
        row-gap: 20px;
      }

      &_title {
        display: block;
        white-space: nowrap;

        &_en {
          display: block;
          font-size: 1.5rem;
          line-height: calc(18 / 15);
          font-weight: 500;
          margin-bottom: 9px;

          @include mixin.sp {
            font-size: 1.1rem;
            line-height: calc(13 / 11);
            margin-bottom: 6px;
          }
        }

        &_ja {
          font-size: 3.4rem;
          line-height: calc(49 / 34);
          font-weight: 700;
          margin: 0;

          @include mixin.sp {
            font-size: 2.2rem;
            line-height: calc(32 / 22);
            letter-spacing: 1.1px;
          }
        }
      }

      &_description {
        font-size: 1.6rem;
        line-height: calc(23 / 16);
        font-weight: 500;
        margin: 0 0 13px;

        @include mixin.sp {
          font-size: 1.4rem;
          line-height: calc(26 / 14);
          margin: 0;
        }
      }
    }

    &_list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 40px;
      row-gap: 50px;
      width: 100%;
      margin: 50px 0 0;
      padding: 0;

      @include mixin.sp {
        column-gap: 15px;
        row-gap: 30px;
        margin-top: 40px;
      }

      &_item {
        display: block;
        width: calc((100% / 2) - (40px / 2));

        @include mixin.sp {
          width: 100%;
          margin-bottom: 10px;
        }

        &:nth-child(n + 3) {
          width: calc((100% / 3) - (80px / 3));

          @include mixin.sp {
            width: calc((100% / 2) - (15px / 2));
            margin-bottom: 0;
          }
        }

        &_inner {
          text-decoration: none;
          color: inherit;

          @include mixin.hover {
            .main_works_list_item_image {
              &_inner {
                transform: scale(1.1);
              }
            }
          }
        }

        &_image {
          display: block;
          width: 100%;
          height: auto;
          aspect-ratio: 1/0.6667;
          overflow: hidden;
          background-color: #fafafa;

          &_inner {
            display: block;
            width: 100%;
            height: 100%;
            transform: scale(1);
            transform-origin: center;
            transition: transform 0.5s ease;

            & > img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        &_title {
          font-size: 1.8rem;
          line-height: calc(26 / 18);
          font-weight: 500;
          margin: 12px 0 0;

          @include mixin.sp {
            font-size: 1.5rem;
            line-height: calc(22 / 15);
            margin-top: 16px;
          }
        }

        &_information {
          display: flex;
          flex-wrap: wrap;
          column-gap: 10px;
          row-gap: 10px;
          width: 100%;
          margin: 6px 0 0;

          @include mixin.sp {
            column-gap: 6px;
            row-gap: 4px;
            margin-top: 4px;
          }

          &_item {
            display: block;
            font-size: 1.3rem;
            line-height: calc(19 / 13);
          }
        }
      }
    }

    &_link {
      display: block;
      width: 100%;
      max-width: 242px;
      font-size: 1.8rem;
      line-height: calc(26 / 18);
      font-weight: 500;
      position: relative;
      padding-right: 44px;
      margin: 83px auto 0;
      color: inherit;
      text-decoration: none;

      @include mixin.sp {
        max-width: 209px;
        font-size: 1.5rem;
        line-height: calc(22 / 15);
        padding-right: 43px;
        margin-top: 51px;
      }

      &_inner {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: color.$site-write;
          transform: scale(0, 1);
          transition: transform 0.3s ease;
          transform-origin: top right;
        }
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 33px;
        height: auto;
        aspect-ratio: 1/1;
        background-color: color.$site-write;
        border-radius: 2px;
        position: absolute;
        top: calc(50% + 1px);
        right: 1px;
        transform: translateY(-50%);
        transition: right 0.3s ease;

        @include mixin.sp {
          width: 27px;
        }
      }

      &::after {
        background-color: transparent;
        background-image: url(../img/common/ico_arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px auto;

        @include mixin.sp {
          background-size: 12px auto;
        }
      }

      @include mixin.hover {
        .main_works_link_inner {
          &::after {
            transform: scale(1, 1);
            transform-origin: top left;
          }
        }

        &::after {
          // right: -3px;
          animation-name: animation_arrow_toright;
          animation-duration: 0.5s;
          animation-timing-function: ease;
        }
      }

      // Modifier
      &-back {
        padding-left: 44px;
        padding-right: 0;

        @include mixin.sp {
          padding-left: 43px;
          padding-right: 0;
        }

        .main_works_link_inner {
          &::after {
            transform-origin: top left;
          }
        }

        &::before,
        &::after {
          top: 50%;
          right: auto;
          left: 1px;
          transform: translateY(-50%) rotate(180deg);
        }

        @include mixin.hover {
          .main_works_link_inner {
            &::after {
              transform: scale(1, 1);
              transform-origin: top right;
            }
          }

          &::after {
            // right: -3px;
            animation-name: animation_linkback_arrow_toleft;
            animation-duration: 0.5s;
            animation-timing-function: ease;
          }
        }
      }
    }
  }

  // Column
  &_column {
    display: block;
    width: 100%;
    max-width: 1120px;
    padding: 77px 0;
    margin: 0 auto;

    @include mixin.sp {
      width: calc(100% - 60px);
      padding: 52px 0;
    }

    &_head {
      display: flex;
      align-items: flex-end;
      column-gap: 70px;
      width: 100%;

      @include mixin.sp {
        flex-direction: column;
        align-items: flex-start;
        column-gap: 0;
        row-gap: 20px;
      }

      &_title {
        display: block;
        white-space: nowrap;

        &_en {
          display: block;
          font-size: 1.5rem;
          line-height: calc(18 / 15);
          font-weight: 500;
          margin-bottom: 9px;

          @include mixin.sp {
            font-size: 1.1rem;
            line-height: calc(13 / 11);
            margin-bottom: 6px;
          }
        }

        &_ja {
          font-size: 3.4rem;
          line-height: calc(49 / 34);
          font-weight: 700;
          margin: 0;

          @include mixin.sp {
            font-size: 2.2rem;
            line-height: calc(32 / 22);
            letter-spacing: 1.1px;
          }
        }
      }

      &_description {
        font-size: 1.6rem;
        line-height: calc(23 / 16);
        font-weight: 500;
        margin: 0 0 13px;

        @include mixin.sp {
          font-size: 1.4rem;
          line-height: calc(26 / 14);
          margin: 0;
        }
      }
    }

    &_list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 38px;
      row-gap: 50px;
      width: 100%;
      margin: 50px 0 0;
      padding: 0;

      @include mixin.sp {
        column-gap: 15px;
        row-gap: 30px;
        margin-top: 40px;
      }

      &_item {
        display: block;
        width: calc((100% / 2) - (38px / 2));

        @include mixin.sp {
          width: 100%;
          margin-bottom: 10px;
        }

        &:nth-child(n + 3) {
          width: calc((100% / 3) - (76px / 3));

          @include mixin.sp {
            width: calc((100% / 2) - (15px / 2));
            margin-bottom: 0;
          }
        }

        &_inner {
          text-decoration: none;
          color: inherit;

          @include mixin.hover {
            .main_column_list_item_image {
              &_inner {
                transform: scale(1.1);
              }
            }
          }
        }

        &_image {
          display: block;
          width: 100%;
          height: auto;
          aspect-ratio: 1/0.526;
          overflow: hidden;
          background-color: #fafafa;

          &_inner {
            display: block;
            width: 100%;
            height: 100%;
            transform: scale(1);
            transform-origin: center;
            transition: transform 0.5s ease;

            & > img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        &_title {
          font-size: 2rem;
          line-height: calc(32 / 20);
          font-weight: 500;
          margin: 16px 0 0;

          @include mixin.sp {
            font-size: 1.5rem;
            margin-top: 14px;
          }
        }

        &_information {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          column-gap: 15px;
          width: 100%;
          margin: 16px 0 0;
          font-size: 1.4rem;
          line-height: calc(20 / 14);

          @include mixin.sp {
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 8px;
            margin-top: 10px;
            font-size: 1.2rem;
          }

          &_category {
            display: flex;
            flex-wrap: wrap;
            column-gap: 6px;
            row-gap: 5px;

            &_item {
            }
          }

          &_date {
          }
        }
      }
    }

    &_link {
      display: block;
      width: 100%;
      max-width: 242px;
      font-size: 1.8rem;
      line-height: calc(26 / 18);
      font-weight: 500;
      position: relative;
      padding-right: 44px;
      margin: 83px auto 0;
      color: inherit;
      text-decoration: none;

      @include mixin.sp {
        max-width: 209px;
        font-size: 1.5rem;
        line-height: calc(22 / 15);
        padding-right: 43px;
        margin-top: 51px;
      }

      &_inner {
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -4px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: color.$site-write;
          transform: scale(0, 1);
          transition: transform 0.3s ease;
          transform-origin: top right;
        }
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 33px;
        height: auto;
        aspect-ratio: 1/1;
        background-color: color.$site-write;
        border-radius: 2px;
        position: absolute;
        top: calc(50% + 1px);
        right: 1px;
        transform: translateY(-50%);
        transition: right 0.3s ease;

        @include mixin.sp {
          width: 27px;
        }
      }

      &::after {
        background-color: transparent;
        background-image: url(../img/common/ico_arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px auto;

        @include mixin.sp {
          background-size: 12px auto;
        }
      }

      @include mixin.hover {
        .main_column_link_inner {
          &::after {
            transform: scale(1, 1);
            transform-origin: top left;
          }
        }

        &::after {
          // right: -3px;
          animation-name: animation_arrow_toright;
          animation-duration: 0.5s;
          animation-timing-function: ease;
        }
      }

      // Modifier
      &-back {
        padding-left: 44px;
        padding-right: 0;

        @include mixin.sp {
          padding-left: 43px;
          padding-right: 0;
        }

        .main_column_link_inner {
          &::after {
            transform-origin: top left;
          }
        }

        &::before,
        &::after {
          top: 50%;
          right: auto;
          left: 1px;
          transform: translateY(-50%) rotate(180deg);
        }

        @include mixin.hover {
          .main_column_link_inner {
            &::after {
              transform: scale(1, 1);
              transform-origin: top right;
            }
          }

          &::after {
            // right: -3px;
            animation-name: animation_linkback_arrow_toleft;
            animation-duration: 0.5s;
            animation-timing-function: ease;
          }
        }
      }
    }
  }

  // Company Guide
  &_companyGuide {
    display: block;
    width: 100%;
    margin: 100px auto 0;
    padding: 80px 0 88px;
    position: relative;

    @include mixin.sp {
      margin-top: 50px;
      padding: 22px 0 28px;
      background-color: #fafafa;
    }

    &::before {
      @include mixin.pc {
        content: "";
        display: block;
        width: calc(100% - 50px);
        height: 100%;
        background-color: #fafafa;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
      }
    }

    &_inner {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      width: 100%;
      max-width: 1120px;
      margin: 0 auto;
      position: relative;
      z-index: 2;

      @include mixin.sp {
        width: calc(100% - 60px);
        row-gap: 0;
      }
    }

    &_item {
      display: block;
      width: 100%;

      &:last-of-type {
        .main_companyGuide_item_text {
          @include mixin.sp {
            border-bottom: none;
          }
        }
      }

      &_inner {
        display: flex;
        align-items: center;
        width: 100%;
        color: inherit;
        text-decoration: none;
        position: relative;

        @include mixin.pc {
          padding-right: 177px;
        }

        @include mixin.sp {
          flex-direction: column;
        }

        &::before,
        &::after {
          content: "";
          display: block;
          width: 33px;
          height: auto;
          aspect-ratio: 1/1;
          background-color: color.$site-write;
          border-radius: 2px;
          position: absolute;
          top: calc(50% + 1px);
          right: 100px;
          transform: translateY(-50%);
          transition: right 0.3s ease;

          @include mixin.sp {
            width: 27px;
            right: 0;
          }
        }

        &::after {
          background-color: transparent;
          background-image: url(../img/common/ico_arrow.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 14px auto;

          @include mixin.sp {
            background-size: 12px auto;
          }
        }

        @include mixin.hover {
          .main_companyGuide_item_image {
            & > img {
              transform: scale(1.1);
            }
          }

          &::after {
            // right: -3px;
            animation-name: animation_arrow_toright;
            animation-duration: 0.5s;
            animation-timing-function: ease;
          }
        }
      }

      &_image {
        display: block;
        width: 200px;
        height: auto;
        aspect-ratio: 1/0.8;
        overflow: hidden;

        @include mixin.sp {
          display: none;
        }

        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }
      }

      &_text {
        display: block;
        width: calc(100% - 200px);
        padding: 0 0 0 40px;

        @include mixin.sp {
          width: 100%;
          padding: 28px 54px 32px 0;
          border-bottom: 1px solid #d9d9d9;
        }

        &_title {
          font-size: 2.6rem;
          line-height: calc(32 / 26);
          font-weight: 500;
          margin: 0 0 20px;

          @include mixin.sp {
            font-size: 1.8rem;
            line-height: calc(28 / 18);
            margin-bottom: 8px;
          }
        }

        &_paragraph {
          font-size: 1.6rem;
          line-height: calc(23 / 16);
          margin: 16px 0 0;

          @include mixin.sp {
            font-size: 1.4rem;
            line-height: calc(24 / 14);
            margin-top: 0;
          }
        }
      }
    }
  }

  // Pager
  &_pager {
    display: block;
    width: 100%;
    margin: 84px 0 0;
    font-size: 1.5rem;
    line-height: calc(20 / 15);
    font-weight: 500;

    @include mixin.sp {
      margin-top: 72px;
      padding: 0 30px;
    }

    &_list {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 23px;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;

      @include mixin.sp {
        flex-wrap: wrap;
        column-gap: 17px;
      }

      &_item {
        display: block;

        // Modifier
        &-prev,
        &-next {
          transform: translateY(-5px);

          @include mixin.sp {
            transform: translateY(-3px);
          }

          a {
            display: block;
            width: 33px;
            height: auto;
            aspect-ratio: 1/1;
            overflow: hidden;
            white-space: nowrap;
            text-indent: 100%;
            position: relative;

            @include mixin.sp {
              width: 28px;
            }

            &::before,
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: auto;
              aspect-ratio: 1/1;
              background-color: color.$site-write;
              border-radius: 2px;
              position: absolute;
              top: 0;
              left: 0;
              transition: right 0.3s ease;

              @include mixin.sp {
                width: 27px;
              }
            }

            &::after {
              background-color: transparent;
              background-image: url(../img/common/ico_arrow.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: 14px auto;

              @include mixin.sp {
                background-size: 12px auto;
              }
            }

            @include mixin.hover {
              &::after {
                // right: -3px;
                animation-name: animation_pager_arrow_toright;
                animation-duration: 0.5s;
                animation-timing-function: ease;
              }
            }
          }
        }
        &-prev {
          a {
            transform: rotate(180deg);
          }
        }
      }
    }

    // WordPress Navigation（get_the_posts_pagination）
    .pagination {
      margin: 0 8px;

      .screen-reader-text {
        display: none !important;
      }

      .nav-links {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 23px;

        @include mixin.sp {
          column-gap: 17px;
        }

        a {
          text-decoration: none;
          color: inherit;
          @include mixin.hoverOpacity60;
        }

        .page-numbers {
          width: 28px;
          padding-bottom: 12px;
          text-align: center;

          &.current {
            border-bottom: 1px solid color.$site-write;
          }
          &.dots {
          }
        }
      }
    }
  }
}

// ==================================================
//  Footer
// ==================================================
.footer {
  display: block;
  width: 100%;
  margin-top: 180px;
  padding-bottom: 121px;

  @include mixin.sp {
    margin-top: 80px;
    padding-bottom: 80px;
  }

  // Inner
  &_inner {
    display: flex;
    justify-content: space-between;
    column-gap: 80px;
    width: 100%;
    max-width: 1120px;
    margin: 120px auto 0;

    @include mixin.sp {
      flex-direction: column-reverse;
      justify-content: flex-start;
      column-gap: 0;
      row-gap: 63px;
      max-width: calc(100% - 60px);
      margin-top: 43px;
    }

    &_left {
    }

    &_right {
      @include mixin.pc {
        padding: 18px 28px 0 0;
      }
    }
  }

  // Logo
  &_logo {
    display: block;
    width: 100%;
    max-width: 138px;
    @include mixin.hoverOpacity60;

    @include mixin.sp {
      max-width: 98px;
    }

    & > img {
      display: block;
      width: 100%;
    }
  }

  // Address
  &_address {
    display: block;
    margin: 32px 0 0;
    font-style: normal;
    font-size: 1.6rem;
    line-height: calc(23 / 16);
    font-weight: 500;

    @include mixin.sp {
      margin-top: 0;
      font-size: 1.4rem;
      line-height: calc(20 / 14);
    }

    & > a {
      display: block;
      color: inherit;
      margin-top: 6px;

      @include mixin.sp {
        margin-top: 10px;
      }
    }
  }

  &_navigation {
    display: flex;
    flex-wrap: wrap;
    column-gap: 83px;

    @include mixin.sp {
      column-gap: 0;
      row-gap: 23px;
      margin-top: 43px;
    }

    &_list {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      list-style: none;
      margin: 0;
      padding: 0;

      @include mixin.sp {
        width: 100%;
        row-gap: 23px;
      }

      &_item {
        font-size: 1.6rem;
        line-height: calc(23 / 16);
        font-weight: 500;
        margin: 0;

        // Modifier
        &-small {
          font-size: 1.3rem;
          line-height: calc(19 / 13);
          margin-top: 9px;

          @include mixin.sp {
            margin-top: 17px;
          }
        }

        &_inner {
          color: inherit;
          text-decoration: none;
          @include mixin.hoverOpacity60;
        }
      }
    }
  }

  &_copyright {
    display: block;
    width: 100%;
    max-width: 1124px;
    font-size: 1.4rem;
    line-height: calc(20 / 14);
    margin: 44px auto 0;
    text-align: right;

    @include mixin.sp {
      max-width: calc(100% - 60px);
      font-size: 1.3rem;
      line-height: calc(19 / 13);
      text-align: left;
    }
  }

  // CTA
  &_cta {
    display: flex;
    column-gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @include mixin.sp {
      flex-direction: column;
      column-gap: 0;
      row-gap: 10px;
      max-width: calc(100% - 20px);
    }

    &_link {
      display: block;
      width: calc((100% / 2) - (20px / 2));
      border: 1px solid #a5a5a5;
      padding: 33px 123px 33px 50px;
      text-decoration: none;
      color: inherit;
      position: relative;

      @include mixin.sp {
        width: 100%;
        padding: 30px 30px 48px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 33px;
        height: auto;
        aspect-ratio: 1/1;
        background-color: color.$site-write;
        border-radius: 2px;
        position: absolute;
        top: calc(50% + 8px);
        right: 41px;
        transform: translateY(-50%);
        transition: right 0.3s ease;

        @include mixin.sp {
          width: 27px;
          top: unset;
          right: 25px;
          bottom: 15px;
          transform: none;
        }
      }

      &::after {
        background-color: transparent;
        background-image: url(../img/common/ico_arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px auto;

        @include mixin.sp {
          background-size: 12px auto;
        }
      }

      @include mixin.hover {
        .footer_cta_link_title_inner {
          &::after {
            transform: scale(1, 1);
            transform-origin: top left;
          }
        }

        &::after {
          // right: 38px;
          animation-name: animation_arrow_toright;
          animation-duration: 0.5s;
          animation-timing-function: ease;
        }
      }

      &_title {
        font-size: 2.2rem;
        line-height: calc(37 / 22);
        font-weight: 500;
        margin: 0;

        @include mixin.sp {
          font-size: 1.8rem;
          line-height: calc(37 / 18);
        }

        &_inner {
          position: relative;

          &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: color.$site-write;
            transform: scale(0, 1);
            transition: transform 0.3s ease;
            transform-origin: top right;
          }
        }
      }

      &_description {
        font-size: 1.5rem;
        line-height: calc(28 / 15);
        margin: 7px 0 0;

        @include mixin.sp {
          font-size: 1.3rem;
          line-height: calc(25 / 13);
          margin-top: 4px;
        }
      }
    }
  }
}

// Animation
@keyframes animation_arrow_toright {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translate3d(50%, -50%, 0px);
  }
  55% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, -50%, 0px);
  }
}

@keyframes animation_linkback_arrow_toleft {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0px) rotate(180deg);
  }
  55% {
    opacity: 0;
    transform: translate3d(50%, -50%, 0px) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, -50%, 0px) rotate(180deg);
  }
}

@keyframes animation_pager_arrow_toright {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translate3d(50%, 0, 0px);
  }
  55% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0px);
  }
  100% {
    opacity: 1;
    transform: translate3d(0px, 0, 0px);
  }
}
